import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import OrderBus from "./orderVerifybus";
import { useSelector } from "react-redux";
import { RootState } from "@/src/redux/store";
import { CancelModal } from "./cancelmodal";
import { ReturnModal } from "./returnModal";
import { CancelReasonCustomer, OrderStatus, pagination, ProductCustomizationOptionOrder, ProductItem, ProductVariation, ProductVariationOption, SalesOrder, SalesOrderLine, SalesOrderStatusHistory } from "@/src/types";
import { useRESTapi } from "@/src/hooks/rest_API";
import { IconLoader } from "@tabler/icons-react";
import { useAlertModal } from "@/src/hooks/alert_modal";
import { Button } from "@/src/components/ui/button";
import { customerFeeds } from "@/src/types/databaseFeeds";
import { Helmet } from "react-helmet-async";


type variationDetails = ProductVariation & {
  variation_option: ProductVariationOption;
};


type singleOrdertype = SalesOrder & {
  isCancellable: boolean,
  isSingleOrderline: number,
  order_status: OrderStatus;
  order_status_dates: SalesOrderStatusHistory[],
  full_order_statuses: OrderStatus[],
  expectedDeliveryDate: string | null,
  orderLines: (SalesOrderLine & {
    productItem: ProductItem & {
      variations: variationDetails[];
    };
    customizations: ProductCustomizationOptionOrder[];
  })[];
};



const Singleorder = () => {
  const [totalItemsPrice, setTotalItemsPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const { orderId } = useParams()
  const finalTotalPrice = totalItemsPrice + totalDiscount;
  const currency = useSelector(
    (state: RootState) => state.configuration.currencyDetails
  );
  const alertModal = useAlertModal()
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId')
  const [singleOrderData, setsingleOrderDAta] = useState<singleOrdertype>();
  const [cancelReasons, setCancelReasons] = useState<CancelReasonCustomer[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const api = useRESTapi();
  const [update, setUpdateUi] = useState(false)
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const navigate = useNavigate()

  const loadCancelReason = async () => {
    try {
      const res = await api.get<{
        success: boolean, data: ({
          data: CancelReasonCustomer[]
        }) & pagination
        , message: string
      }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/cancel_reasons`,
        mode: 'customer',
      })

      if (res.data.success === true) {
        setCancelReasons(res.data.data.data)

      } else {
        alertModal.open_alert_modal({
          content: res.data.message,
          footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
          title: 'Error'
        })
      }
    } catch (error) {
      alertModal.open_alert_modal({
        content: 'Server Error',
        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
        title: 'Error'
      })
    }

  }

  const get_order_details = async () => {
    try {

      const res = await api.get<{
        success: boolean;
        data: singleOrdertype;
        message: string;
      }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/order_item/${orderId}`,
        mode: "customerPrivate",
        params: {
          userId: userId
        }
      });

      if (res.data.success === true) {
        // res.data.data.order_status = {
        //   "I030_order_status_id": 3,
        //   "I030_order_name": "cancelled"
        // }
        // res.data.data.order_status_dates = [
        //   {
        //     "I31_I19_order_id": '17',
        //     "I31_I030_status": 1,
        //     "I31_status_change_date": "2024-07-17 23:13:20"
        //   },

        //   {
        //     "I31_I19_order_id": '17',
        //     "I31_I030_status": 12,
        //     "I31_status_change_date": "2024-07-17 23:13:20"
        //   },
        //   {
        //     "I31_I19_order_id": '17',
        //     "I31_I030_status": 3,
        //     "I31_status_change_date": "2024-07-17 23:13:20"
        //   },

        // ]
        setsingleOrderDAta(res.data.data);
      } else if (res.data.success === false) {
        alertModal.open_alert_modal({
          content: res.data.message,
          footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
          title: 'Error'
        })
      }
    } catch (error) {
      console.log(error);
      alertModal.open_alert_modal({
        content: 'Server Error',
        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
        title: 'Error'
      })
    }
  };

  useEffect(() => {
    const calculateTotals = () => {
      if (singleOrderData) {
        const totalItemsPrice = singleOrderData.orderLines.reduce(
          (total, item) => total + item.I21_Price * item.I21_Quantity,
          0
        );
        const totalDiscount = singleOrderData.orderLines.reduce(
          (total, item) => total + item.I21_discount_price * item.I21_Quantity,
          0
        );
        setTotalItemsPrice(totalItemsPrice);
        setTotalDiscount(totalDiscount);
      }
    };

    calculateTotals();
  }, [singleOrderData]);

  const updateUi = () => {
    setUpdateUi(!update)
  }

  useEffect(
    () => {
      setIsLoading(true);
      Promise.all(
        [get_order_details(), loadCancelReason()]
      ).then(
        res => {
          setIsLoading(false)
        }
      ).catch(res => {
        alertModal.open_alert_modal({
          content: 'Error loading order details',
          footer: <Button onClick={() => {
            alertModal.close_alert_modal();
            navigate('/')
          }}>Ok</Button>,
          title: 'Error'
        })
      })

    }, [update]
  )


  return (
    <div className="bg-blue-gray-400 w-full h-auto pt-4 pb-4 flex gap-8 lg:gap-16 px-4 lg:px-8 relative flex-col lg:flex-row">
      <Helmet>
        <link rel="canonical" href={window.location.href} />

      </Helmet>

      {isLoading === true && (
        <div className=" absolute inset-0 flex justify-center items-center z-[500] bg-gray-800/20">
          <div className=" animate-spin ">
            <IconLoader />
          </div>
        </div>
      )}
      {
        singleOrderData ?
          <>
            <div className="addressdiv bg-white rounded-lg w-full lg:w-3/5">
              <div className="p-2 font-[500] text-blue-gray-500 italic">
                Order Id : {singleOrderData.I19_order_id}
              </div>
              <hr />
              {singleOrderData.orderLines.map((item, index) => (
                <div key={index}>
                  <hr />
                  <div className="detailsofproducts p-7 flex justify-between cursor-pointer">
                    <div className="flex flex-col gap-[6px] justify-center">
                      <div className="font-bold text-[1.3rem]">
                        {item.productItem.M08_ProductItemName}
                      </div>
                      {item.productItem.variations.map((varItem, index) => (
                        <div className="" key={index}>
                          <div className="opacity-70">
                            {varItem.M06_Name} : {varItem.variation_option.M07_Value}
                          </div>
                        </div>
                      ))}
                      <div className="opacity-70">Quantity : {item.I21_Quantity}</div>
                      <div className="priceofproduct font-semibold">
                        Price :{" "}
                        <span className="me-1">{currency.M05_CurrencySymbol}</span>
                        {item.I21_Price}
                      </div>
                    </div>
                    <div className="image">
                      <img
                        className="w-24 h-24 rounded"
                        src={`${process.env.REACT_APP_BACKEND_URL}/${item.productItem.M08_ProductImage}` as string}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ))}
              <hr />
              <div className="mt-6 grow sm:mt-8 lg:mt-0">
                <div className="space-y-6 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Order history
                  </h3>
                  <ol className="relative ml-3 border-s-2 border-gray-300 dark:border-gray-700">
                    {/* order Route  */}
                    <OrderBus
                      fullStatuses={singleOrderData.full_order_statuses}
                      currentStatuses={singleOrderData.order_status_dates}
                      currentStop={singleOrderData.order_status.I030_order_name}
                      expectedDeliveryDate={singleOrderData.expectedDeliveryDate}
                    />
                  </ol>
                  {userId != customerFeeds.logoutCustomer.M01_CustomerID && <div className="flex flex-col sm:flex-row justify-end gap-4">
                    <button
                      type="button"
                      className={` rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium ${singleOrderData.order_status.I030_order_name ===
                        "shipped" ||
                        singleOrderData.order_status.I030_order_name ===
                        "delivered"
                        ? "text-gray-400 cursor-not-allowed bg-gray-200"
                        : "text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                        }`}
                      disabled={!singleOrderData.isCancellable}
                      onClick={() => setShow1(true)}
                    >
                      Cancel the order
                    </button>

                    {/* <button
                      type="button"
                      className={`mt-4 sm:mt-0 w-full flex items-center justify-center rounded-lg px-5 py-2.5 text-sm font-medium text-white ${singleOrderData.order_status.I030_order_name ===
                        "delivered"
                        ? "bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        : "bg-gray-200 text-gray-400 cursor-not-allowed hover:bg-gray-200"
                        }`}
                      disabled={
                        singleOrderData.order_status.I030_order_name !==
                        "delivered"
                      }
                      onClick={() => setShow2(true)}
                    >
                      Return
                    </button> */}
                  </div>}
                </div>
              </div>

              <hr />
              <div className="address p-4 ">
                <div className="opacity-80 text-[13px]">Shipping Details</div>
                <div className="text-[16px] font-semibold">
                  {singleOrderData.I19_customer_address_name}
                </div>
                <div className="text-[16px]">
                  {singleOrderData.I19_customer_address}
                </div>

              </div>
            </div>

            <div className="orderSummary flex-1 space-y-6 lg:mt-0 static mx-0 lg:sticky top-4 h-min w-full lg:w-3/5">
              <div className="space-y-4 rounded-lg bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                <p className="text-xl font-semibold text-gray-900 dark:text-white">
                  Order Details
                </p>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Total Price
                      </dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        {singleOrderData.I19_total_price}
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Discount
                      </dt>
                      <dd className="text-base font-medium text-green-600">
                        {singleOrderData.I19_discount_price}
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Tax Amount
                      </dt>
                      <dd className="text-base font-medium text-green-600">
                        {singleOrderData.I19_tax_price}
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Delivery Charge
                      </dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        {singleOrderData.I19_Delivery_charge}
                      </dd>
                    </dl>
                  </div>
                  <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                    <dt className="text-base font-bold text-gray-900 dark:text-white">
                      Total
                    </dt>
                    <dd className="text-base font-bold text-gray-900 dark:text-white">
                      <span className="me-1">{currency.M05_CurrencySymbol}</span>
                      {Number(singleOrderData.I19_total_price) +
                        Number(singleOrderData.I19_Delivery_charge ? singleOrderData.I19_Delivery_charge : 0)}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </>
          :
          null
      }
      <CancelModal updateUi={updateUi} orderId={String(singleOrderData?.I19_order_id)} show={show1} setShow={setShow1} options={cancelReasons.map(item => (
        {
          name: item.I33_reason,
          value: String(item.I33_reason_id)
        }
      ))} />
      <ReturnModal show={show2} setShow={setShow2} />
    </div>
  );
};

export default Singleorder;
