import { useEffect, useState } from 'react';
import CarauselShadecn from '../../components/carausel/Carausel';
// import { CategoryTab } from '../../components/catagoryTab/categoryTab'
import Footer from '../../components/Footer/Footer';
import Topcatagories from '../../components/TopCatagories/Topcatagories';
import CarouselProducts from '../../components/carouselProducts/carouselProducts';
import TopBrands from '../../components/topbrands/Topproducts';
import { useConfiguration } from 'src/hooks/configuration_hook';
import { EmblaOptionsType } from 'embla-carousel'
import { OfferCarousel } from '../../components/offerCarousel/offerCarousel';
import ShippingSystem from './shipmentMethod';
import { Helmet } from 'react-helmet-async';


// const OPTIONS: EmblaOptionsType = { loop: true }
// const SLIDE_COUNT = 5
// const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

export default function Home() {

  const offers = [
    { key: 'firstOffer', text: 'BUY 4, PAY FOR 3' },
    { key: 'secondOffer', text: '75% OFF MRP' },
    { key: 'thirdOffer', text: '40% OFF MRP' },
    // { key: 'secondOffer', text: '75% OFF MRP' },

  ];
  const [mostUsedColor, setMostUsedColor] = useState<string>('#0396ae')
  const {
    show_category_tab,
    hide_category_tab,
    set_category_tab_theme
  } = useConfiguration()

  useEffect(
    () => {
      const handleScroll = () => {
        const currentScrollPos = window.scrollY;

        if (currentScrollPos > 200) {
          set_category_tab_theme({ theme: 'colorful' })
        } else {
          set_category_tab_theme({ theme: 'colorful' })
        }

        // Update the previous scroll position
      };

      window.scrollTo({ top: 0 })
      // Add event listener for scroll event
      set_category_tab_theme({ theme: 'colorful' })

      window.addEventListener('scroll', handleScroll);

      // Clean up by removing event listener when the component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []
  )


  return (
    <div className='w-full max-w-[2000px]  mx-auto '>
      <Helmet>
        <title>Buy Commercial Kitchen Equipment Online in UAE | Trusted Supplier - Chefssouq</title>
        <meta name="description"
          content="Looking to buy commercial kitchen equipment online in the UAE? Chefssouq offers a wide range of top-quality kitchen equipment for restaurants. Shop online now for competitive prices and expert service!" />
        <link rel="canonical" href="https://chefssouq.com" />

      </Helmet>

      <div className='w-full grid grid-cols-6 gap-3 p-3' style={{ backgroundColor: `${mostUsedColor}` }}>
        <div className=' md:col-span-4 col-span-6'>
          <CarauselShadecn onChangeColor={(color) => setMostUsedColor(color)} />
        </div>
        <div className=' hidden md:block md:col-span-2 '>
          <OfferCarousel />
        </div>
      </div>
      {/* <div className='w-full  items-center justify-center bg-slate-500 p-4'><CarauselShadecn  /></div> */}
      <div className=' flex  gap-4 w-full h-auto bg-blue-gray-900 justify-center font-bold overflow-x-scroll ' style={{ scrollbarWidth: 'none', justifyContent: 'flex-start' }}>
        {/* {offers.map((offer) => (
          <div key={offer.key} className={`offer ${offer.key} bg-[#bdbdbd4a] p-3 m-3 rounded flex direction-normal  min-w-[80%] max-h-28 sm:min-w-[210px] `}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-discount-2" width="55" height="55" viewBox="0 0 24 24" stroke-width="0.5" stroke="#ff4343" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 15l6 -6" />
                <circle cx="9.5" cy="9.5" r=".5" />
                <circle cx="14.5" cy="14.5" r=".5" />
                <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
              </svg>
            </div>
            <div>
              <span className='text-[#ff4343]'>{offer.text}</span> <br />
              <span className='text-[#ffffffc0]'>SELECT SALISBURY & CO CLASSIC TABLEWARE</span>
            </div>

          </div>
        ))} */}
      </div>

      <div className="relative">
        <div className='absolute inset-0 opacity-40' style={{ background: `linear-gradient(to bottom, ${mostUsedColor}, #b7b7b7)` }} ></div>
        {/* <h2 className='pt-5 text-[15px] font-bold  text-center '>Most selling products</h2> */}

        <CarouselProducts />

        <div className='p-7 relative text-[white] pb-14 ' style={{ background: `radial-gradient(circle, ${mostUsedColor}, #dcdddd)` }}>

          <h2 className='pt-5 text-[25px] font-bold  pl-7 text-center'>Top Selling Catagories </h2>

          <Topcatagories />
        </div>



        <h2 className='pt-5 text-[25px] font-bold  pl-7'>Top Brands</h2>
        <TopBrands />

        <ShippingSystem />

      </div>

      <Footer />


    </div>
  )
}

